import * as React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Switch as RouterSwitch } from "react-router-dom";

import { ErrorBoundary } from "components/errorBoundary";
import PageSpinner from "components/pageSpinner";
import { QueryClientProvider } from "components/queryClient";
import { handleAnonymousError } from "errorPages/anonymousError";
import { AjaxManager, useAjaxManager } from "utils/ajax";
import { withHeader, Meta } from "utils/header";
import withI18n, { t } from "utils/i18n";
import { initializeSentry } from "utils/sentry";

import CheckInbox from "./checkInbox";
import ForgotPassword from "./forgotPassword";
import ForgotTeam from "./forgotTeam";
import LinkExpired from "./linkExpired";
import TeamList from "./teamList";
import TeamLogin from "./teamLogin";
import "main.css";

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const _main = require("main.module.scss");


const ENTRYPOINT_KEY_NO_ORG = "noOrg";

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            status: { type: "LOGIN" },
        };
    }

    render() {
        const { ajaxManager } = this.props;
        return (<>
            <Meta title={() => t("Login_Title")} />
            <RouterSwitch>
                <Route exact path="/">
                    <TeamLogin ajaxManager={ajaxManager} />
                </Route>
                <Route exact path="/forgot" render={props => <ForgotTeam ajaxManager={ajaxManager} {...props} />} />
                <Route exact path="/check/:email" render={props => <CheckInbox {...props} />} />
                <Route path="/password">
                    <ForgotPassword />
                </Route>
                <Route path="/expired">
                    <LinkExpired />
                </Route>
                <Route exact path="/choose-org/:token" render={props =>
                    <TeamList ajaxManager={ajaxManager} {...props} />} />
                <Route render={() => <>
                    <Meta title={() => t("ErrorPage_NotFound")} />
                    {handleAnonymousError(404)}
                </>} />
            </RouterSwitch>
        </>);
    }
}

interface AppState {
    status: { type: "LOGIN" },
}
interface AppProps {
    ajaxManager: AjaxManager,
}

const AppWithI18n = withI18n<AppProps>(withHeader<AppProps>(App));

const LocalizedApp = () => {
    const ajaxManager = useAjaxManager();
    return (
        <ErrorBoundary routeKey={ENTRYPOINT_KEY_NO_ORG}>
            <QueryClientProvider>
                <Router>
                    <React.Suspense fallback={<PageSpinner />}>
                        <AppWithI18n ajaxManager={ajaxManager} />
                    </React.Suspense>
                </Router>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

initializeSentry(ENTRYPOINT_KEY_NO_ORG, "v2");
const container = document.getElementById("app");
const root = createRoot(container!);

root.render(<LocalizedApp />);
