import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { UserOutlined } from "@ant-design/icons";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CardFooter, FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import { Input } from "antd";
import axios from "axios";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import { APIRoute } from "routes";
import { SendOrgChooserEmailData } from "types/web/global/login_flow";
import { AjaxManager } from "utils/ajax";
import { isEmailValid } from "utils/form";
import { t } from "utils/i18n";

import RightSideText from "./rightSideText";
import theme from "./theme.module.scss";

const FormItem = Form.Item;

class ForgotTeam extends React.Component<ForgotTeamProps, ForgotTeamState> {
    constructor(props: ForgotTeamProps) {
        super(props);
        this.state = {
            status: { type: "DEFAULT" },
        };
    }

    handleForgottenTeam = (e: React.FormEvent<any>) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values) => {
            if (!err) {
                const { email, captcha } = values;
                const { ajaxManager } = this.props;
                const requestData: SendOrgChooserEmailData = { email, humanVerificationResponse: captcha };
                ajaxManager.ajax<any, ForgotTeamStatus>(
                    () => axios.post(`${APIRoute.ROOT}/send-org-chooser-email`, requestData), {
                        component: this,
                        initialStatusValue: "DEFAULT",
                        getSuccessStatus: () => ({ type: "DEFAULT" }),
                        getErrorStatus: () => ({ type: "DEFAULT" }),
                        inFlightStatus: { type: "LOGGING_IN" },
                    })
                    .then(() => this.props.history.push("/check/" + email));
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <FormCardContainer rightSide={<RightSideText />}>
                <FormTitle title={t("LoginFlow_FindOrganization")} />
                <FormSubtitle subtitle={t("LoginFlow_EmailToConfirm")} />
                <FormCard onSubmitForm={this.handleForgottenTeam}>
                    <FormItem>
                        {getFieldDecorator("email", {
                            rules: [{
                                required: true,
                                message: t("LoginFlow_InputCorrectEmail"),
                            }, {
                                validator: (_rule: any, value: string | undefined, callback: any) => {
                                    if (!isEmailValid(value)) {
                                        callback(t("LoginFlow_InputCorrectEmail"));
                                    } else {
                                        callback();
                                    }
                                },
                            }],
                        })(
                            <Input prefix={<UserOutlined className={theme.iconOpacity} />} placeholder={t("Login_UserName")} />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator("captcha", {
                            rules: [{ required: true }],
                        })(
                            <div className={theme.hcaptchaContainer}>
                                <HCaptcha
                                    size="normal"
                                    sitekey={IMPERO_HCAPTCHA_SITE_KEY}
                                    onVerify={this.handleCaptchaVerify}
                                />
                            </div>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button
                            id="loginFormSubmitBtn"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.status.type === "LOGGING_IN"}
                            block>
                            {t("LoginFlow_SendMeAnEmail")}
                        </Button>
                    </FormItem>
                </FormCard>

                <CardFooter>
                    <a href="https://impero.com/get-in-touch/" target="_blank">{t("LoginFlow_DontHaveAccount")}</a>
                </CardFooter>
            </FormCardContainer>);
    }

    private handleCaptchaVerify = (captcha: string | null) => {
        this.props.form.setFieldsValue({
            captcha,
        });
    };
}

const WrappedForgotTeam = Form.create<ForgotTeamProps>()(ForgotTeam);

export default WrappedForgotTeam;

interface ForgotTeamProps extends FormComponentProps {
    history?: any,
    ajaxManager: AjaxManager,
}

type ForgotTeamStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" };
interface ForgotTeamState {
    status: ForgotTeamStatus,
}
