import * as React from "react";

import { CardFooter, FormTitle, FormSubtitle, Button } from "@imperocom/ui";
import { List, Spin } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";


import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import findYourTeam from "img/find-your-team.svg";
import { APIRoute } from "routes";
import { RedirectTarget } from "types/web/global/login_flow";
import { AjaxManager } from "utils/ajax";
import { t } from "utils/i18n";
import { emitNotification } from "utils/notification";

import theme from "./theme.module.scss";


type OrgArrayOrNull = Org[] | null;

export default class TeamList extends React.Component<TeamListProps, TeamListState> {
    constructor(props: TeamListProps) {
        super(props);
        this.state = {
            data: [],
            status: { type: "INITIAL" },
        };
    }

    componentDidMount() {
        this.fetchTeams();
    }

    fetchTeams() {
        const { ajaxManager } = this.props;
        ajaxManager.ajax<OrgArrayOrNull, TeamListStatus>(
            () => axios.post<OrgArrayOrNull>(`${APIRoute.ROOT}/access-token`, { "token": this.props.match.params.token }), {
                component: this,
                initialStatusValue: "INITIAL",
                getErrorStatus: () => ({ type: "INITIAL" }),
                getSuccessStatus: (data: [OrgArrayOrNull]) => {
                    if (data === null || data[0] === null || data[0] === undefined) {
                        this.props.history.push("/expired");
                        return { type: "INITIAL" };
                    } else {
                        this.setState({ data: data[0] });
                        return { type: "DEFAULT" };
                    }
                },
                inFlightStatus: { type: "LOADING" },
                onErrorCode: {
                    400: () => {
                        emitNotification("warning", t("LoginFlow_NoOrgFound"));
                    },
                },
            });
    }

    handleLogin = (orgShortName: string) => {
        const { ajaxManager } = this.props;
        ajaxManager.ajax<RedirectTarget, TeamListStatus>(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            () => axios.post(`${APIRoute.ROOT}/enter-org`, { "org_short_name": orgShortName }), {
                component: this,
                initialStatusValue: "DEFAULT",
                getSuccessStatus: () => ({ type: "DEFAULT" }),
                getErrorStatus: () => ({ type: "DEFAULT" }),
                inFlightStatus: { type: "LOGGING_IN" },
            })
            .then((response: RedirectTarget) => {
                window.location.href = response.redirect;
            });
    };

    render() {
        const { status } = this.state;
        return (
            <FormCardContainer rightSide={<RightSideIcon icon={findYourTeam} customImages={false} />}>
                <FormTitle title={t("LoginFlow_YourOrganizations")} />
                <span className={this.state.data && !this.state.data.length ? theme.formDisplayNone : ""}>
                    <FormSubtitle subtitle={t("LoginFlow_FollowingOrganizationsFound")} />
                </span>
                {status.type === "INITIAL" ?
                    <Spin size="large" tip={t("LoginFlow_LoadingOrgList")} className={theme.loadingOrgList} /> :
                    <List
                        className={theme.formTeamList}
                        dataSource={this.state.data}
                        locale={{ emptyText: t("LoginFlow_EmailHasNoOrganization") }}
                        renderItem={(item: Org) => (
                            <List.Item key={item.short_name}>
                                <List.Item.Meta title={<><b>{item.short_name}</b>.impero.com</>} />
                                <div>
                                    <Button
                                        onClick={() => this.handleLogin(item.short_name)}
                                        type="primary"
                                        id={`teamLoginBtn-${item.short_name}`}
                                        className={theme.formTeamListButton}
                                        ghost>
                                        {t("LoginFlow_LoginNow")}
                                    </Button>
                                </div>
                            </List.Item>
                        )} />}

                <CardFooter className={theme.formTeamListFooter}>
                    <Link to="/forgot">{t("LoginFlow_DidntFindTeam")}</Link>
                </CardFooter>
            </FormCardContainer>
        );
    }
}

interface Org {
    name: string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    short_name: string,
}

interface TeamListProps {
    match?: any,
    history?: any,
    ajaxManager: AjaxManager,
}

type TeamListStatus = { type: "INITIAL" } | { type: "DEFAULT" } | { type: "LOADING" } | { type: "LOGGING_IN" };
interface TeamListState {
    status: TeamListStatus,
    data?: Org[],
}
